.withdraw-radio-group-wrapper {
    margin: -24px -16px 16px -16px;

    @media (min-width: $screen-lg) {
        margin: -24px -24px 16px -24px;
    }

    .withdraw-radio-group {
        width: 100%;
        display: flex;

        .ant-radio-button-wrapper {
            flex: 1 0;
            font-size: 24px;
            text-align: center;
            border: 0;
            background: rgba(0, 0, 0, 0.3);

            &.ant-radio-button-wrapper-checked {
                background: $gradient-primary;
                color: var(--color-text-primary-bg);
                text-shadow: 0 2px 4px var(--color-text-shadow-primary-bg);
                box-shadow: 0 8px 16px -6px hsla(var(--color-primary-hsl), 0.75),
                    inset 0 -1px 0 0 rgba($color-white, 0.2);
            }

            &:first-child {
                border-start-start-radius: 32px;
                border-end-start-radius: 0;
            }

            &:last-child {
                border-start-end-radius: 32px;
                border-end-end-radius: 0;
            }

            &:not(:first-child)::before {
                display: none;
            }
        }
    }
}