.ant-redeem-success-popup {
    .ant-modal-content {
        padding: 0 !important;
        margin: 0 -6px;
    }

    .ant-modal-close {
        display: none;
    }
}

.loaded-images {
    opacity: 0 !important;
    z-index: -1 !important;
}

.redeem-success-container {
    position: relative;
    width: 100%;
    max-width: 512px;
    overflow: hidden;

    .redeem-success-title {
        position: absolute;
        top: calc(50% - 160px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 56px;
        text-shadow: 0 6px 16px #fdf66d;
        opacity: 0;
        animation: fadeIn 0.25s 1.35s 1 normal both;
    }

    .redeem-success-result {
        position: absolute;
        top: calc(50% + 160px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 36px;
        text-shadow: 0 6px 16px #fdf66d;
        white-space: nowrap;
        opacity: 0;
        animation: fadeIn 0.25s 1.35s 1 normal both;
    }

    .reddem-success-close {
        position: absolute;
        top: calc(50% + 220px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
        cursor: pointer;
        opacity: 0;
        animation: fadeIn 0.25s 1.35s 1 normal both;
    }

    .polar {
        position: relative;
        top: 0;
        left: 50%;
        width: 150%;
        transform: translateX(-50%) scale(0) rotate(0deg);
        opacity: 0;

        &.animated {
            animation: polarFadeIn 0.5s 1.35s 1 normal both,
                polarRotate 15s 1.35s infinite linear both;
        }

        @media (min-width: $screen-md) {
            width: 100%;
        }
    }

    .gift-bottom {
        position: absolute;
        top: calc(50% + 40px);
        left: 50%;
        transform: translate(-50%, -50%);

        &.animated {
            animation: shakeBox 1s both;
        }
    }

    .gift-top {
        position: absolute;
        top: calc(50% - 10px);
        left: 50%;
        transform: translate(-50%, -50%);

        &.animated {
            animation: shakeBox 1s 0.25s 1 normal both,
                topBoxFade 0.25s 1.25s 1 normal forwards;
        }

    }

    .light-in-box {
        position: absolute;
        top: calc(50% - 60px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: fadeIn 0.25s 1.35s 1 normal both;
        }
    }

    .coin-container {
        position: absolute;
        top: calc(50% - 50px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: coinFadeScale 0.25s 1.35s 1 normal both,
                coinFloating 1s 1.35s infinite normal both;
        }

        .coin-amount {
            position: absolute;
            top: calc(50% - 2px);
            left: calc(50% - 2px);
            transform: translate(-50%, -50%) skewX(-25deg);
            font-size: 48px;
            font-weight: bold;
            color: #e67d08;
            text-shadow: 1px 1px #fdf66d, -1px -1px #d36f06;

            &.text-small {
                font-size: 36px;
                letter-spacing: 2px;
            }
        }
    }

    .flare-1 {
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 10px);
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: coinFadeScale 0.25s 1.35s 1 normal both,
                coinFloating 0.75s 1.35s infinite normal both;
        }
    }

    .flare-2 {
        position: absolute;
        top: calc(50% - 70px);
        left: calc(50% - 45px);
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: coinFadeScale 0.25s 1.35s 1 normal both,
                coinFloating 1.25s 1.35s infinite normal both;
        }
    }

    .flare-3 {
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% + 40px);
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: coinFadeScale 0.25s 1.35s 1 normal both,
                coinFloating 1.5s 1.35s infinite normal both;
        }
    }

    .flare-4 {
        position: absolute;
        top: calc(50% - 120px);
        left: calc(50% + 45px);
        transform: translate(-50%, -50%);
        opacity: 0;

        &.animated {
            animation: coinFadeScale 0.25s 1.35s 1 normal both,
                coinFloating 2s 1.35s infinite normal both;
        }
    }

    .congrat {
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        opacity: 0;
        width: 400px;
        transform: translate(-50%, -50%);

        &.animated {
            animation: congratIn 0.5s 2s 1 normal both,
                congratOut 2s 1.35s 1 normal forwards;
        }
    }

}

// Keyframes
@keyframes shakeBox {
    0% {
        transform: translate(calc(-50% + 1px), calc(-50% + 1px)) rotate(0deg);
    }

    10% {
        transform: translate(calc(-50% - 1px), calc(-50% - 2px)) rotate(-1deg);
    }

    20% {
        transform: translate(calc(-50% - 3px), -50%) rotate(1deg);
    }

    30% {
        transform: translate(calc(-50% + 3px), calc(-50% + 2px)) rotate(0deg);
    }

    40% {
        transform: translate(calc(-50% + 1px), calc(-50% - 1px)) rotate(1deg);
    }

    50% {
        transform: translate(calc(-50% - 1px), calc(-50% + 2px)) rotate(-1deg);
    }

    60% {
        transform: translate(calc(-50% - 3px), calc(-50% + 1px)) rotate(0deg);
    }

    70% {
        transform: translate(calc(-50% + 3px), calc(-50% + 1px)) rotate(-1deg);
    }

    80% {
        transform: translate(calc(-50% - 1px), calc(-50% - 1px)) rotate(1deg);
    }

    90% {
        transform: translate(calc(-50% + 1px), calc(-50% + 2px)) rotate(0deg);
    }

    100% {
        transform: translate(calc(-50% + 1px), calc(-50% - 2px)) rotate(-1deg);
    }
}

@keyframes topBoxFade {
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -200%);
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes coinFadeScale {
    0% {
        opacity: 0;
        transform: translate(-50%, 20%) scale(0);
    }

    75% {
        opacity: 1;
        transform: translate(-50%, -100%) scale(1.1);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes coinFloating {

    0% {
        transform: translate(-50%, -50%);
    }

    50% {
        transform: translate(-50%, calc(-50% - 10px));
    }

    10% {
        transform: translate(-50%, -50%);
    }
}

@keyframes polarFadeIn {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0) rotate(0deg);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1) rotate(0deg);
    }
}

@keyframes polarRotate {
    0% {
        transform: translateX(-50%) scale(1) rotate(0deg);
    }

    100% {
        transform: translateX(-50%) scale(1) rotate(360deg);
    }
}

@keyframes congratIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes congratOut {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
    }
}