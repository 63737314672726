.auth-container {
    width: 100%;
    padding: 16px 16px 32px;
    margin-bottom: 40px;

    .avatar-logo {
        display: block;
        margin: 0 auto 16px;
    }

    @media (min-width: $screen-md) {
        width: 440px;

        .avatar-logo {
            margin: 0 auto;
        }
    }
}

.captcha-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    button {
        margin-left: 16px;
    }

    img {
        border: 4px solid $color-primary;
        border-radius: 0.25rem;
    }
}

.otp-input-group {
    display: flex;

    .ant-input {
        font-family: Roboto, Noto Sans Thai, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 32px !important;
        text-align: center;
        height: 72px;

        &:hover {
            border-color: rgb(67, 67, 67);
        }

        &:focus, &:focus-within {
            border-color: $color-primary !important;
        }
    }
}